// SantaLetter.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ChristmasCountdown from './components/ChristmasCountdown';
import './App.css'; // Ensure CSS is imported

function SantaLetter() {
  const navigate = useNavigate();
  // State variables
  const [name, setName] = useState('');
  const [gender, setGender] = useState('');
  const [age, setAge] = useState('');
  const [wishlist, setWishlist] = useState(['']);
  const [favouriteReindeer, setFavouriteReindeer] = useState('');
  const [address, setAddress] = useState('');
  const [importantPeople, setImportantPeople] = useState([{ relation: '', name: '' }]);
  const [school, setSchool] = useState('');
  const [messageToSanta, setMessageToSanta] = useState('');
  const [loading, setLoading] = useState(false);
  const [sent, setSent] = useState(false);
  const [email, setEmail] = useState('');

  // Handlers for wishlist items
  const handleWishlistChange = (index, event) => {
    const newWishlist = [...wishlist];
    newWishlist[index] = event.target.value;
    setWishlist(newWishlist);
  };

  const addWishlistItem = () => {
    setWishlist([...wishlist, '']);
  };

  const removeWishlistItem = (index) => {
    const newWishlist = wishlist.filter((_, i) => i !== index);
    setWishlist(newWishlist);
  };

  // Renamed handlers
  const handleImportantPeopleChange = (index, field, value) => {
    const newImportantPeople = [...importantPeople];
    newImportantPeople[index][field] = value;
    setImportantPeople(newImportantPeople);
  };

  const addImportantPerson = () => {
    if (importantPeople.length >= 10) {
      alert("You can only add up to 10 important people!");
      return;
    }
    setImportantPeople([...importantPeople, { relation: '', name: '' }]);
  };

  const removeImportantPerson = (index) => {
    const newImportantPeople = importantPeople.filter((_, i) => i !== index);
    setImportantPeople(newImportantPeople);
  };

  // Handler for form submission
  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    const API_URL = process.env.NODE_ENV === 'production' 
      ? 'https://api.christmas-letter.com/santa'
      : 'http://localhost:3001/santa';

    const data = {
      name,
      gender,
      age,
      wishlist,
      favouriteReindeer,
      address,
      importantPeople,
      school,
      messageToSanta,
      email,
    };

    try {
      const response = await fetch(API_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      if (response.status === 429) {
        throw new Error('Too many letters sent. Please try again in 24 hours or contact the admin.');
      }

      if (!response.ok) {
        throw new Error('Failed to send letter to Santa');
      }

      setLoading(false);
      setSent(true);
    } catch (error) {
      console.error('Error sending letter:', error);
      setLoading(false);
      alert(error.message || 'Failed to send letter to Santa. Please try again!');
    }
  };

  // Handler to reset the form
  const resetForm = () => {
    setName('');
    setGender('');
    setAge('');
    setWishlist(['']);
    setFavouriteReindeer('');
    setAddress('');
    setImportantPeople([{ relation: '', name: '' }]);
    setSchool('');
    setMessageToSanta('');
    setEmail('');
    setSent(false);
  };

  if (loading) {
    return (
      <div className="loading">
        <h2>Loading...</h2>
      </div>
    );
  }

  if (sent) {
    return (
      <div className="sent-message">
        <h2>Your letter has been sent to Santa! You should receive an email within a few minutes. (Be sure to check your spam folder!)</h2>
        <button onClick={resetForm}>Send Another Letter</button>
      </div>
    );
  }

  return (
    <div className="container">
      <ChristmasCountdown />
      <div className="form-wrapper">
        <img 
          src="/santa.webp" 
          alt="Santa Claus" 
          className="santa-image"
        />
        <h1>Write a Letter to Santa</h1>
        <div className="preview-section">
          <p className="email-notice">✨ Santa's elves will send a magical copy of your letter to your email! ✨</p>
          <button 
            className="preview-btn"
            onClick={() => navigate('/letter/1cc37f39d5d53dd2c95d1c7ecb60ff057551ab108b5fd2b16e0b8ad7729b3263')}
          >
            Preview Santa's Magical Letter
          </button>
        </div>
        <form onSubmit={handleSubmit}>
          {/* Name */}
          <div>
            <label>Name:</label>
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </div>

          {/* Gender */}
          <div>
            <label>Gender:</label>
            <label>
              <input
                type="radio"
                value="Boy"
                checked={gender === 'Boy'}
                onChange={(e) => setGender(e.target.value)}
                required
              />
              Boy
            </label>
            <label>
              <input
                type="radio"
                value="Girl"
                checked={gender === 'Girl'}
                onChange={(e) => setGender(e.target.value)}
                required
              />
              Girl
            </label>
          </div>

          {/* Age */}
          <div>
            <label>Age:</label>
            <input
              type="number"
              value={age}
              onChange={(e) => setAge(e.target.value)}
              required
            />
          </div>

          {/* Wishlist */}
          <div>
            <label>Wishlist:</label>
            {wishlist.map((item, index) => (
              <div key={index} className="input-group">
                <input
                  type="text"
                  value={item}
                  onChange={(e) => handleWishlistChange(index, e)}
                  required
                />
                <button
                  type="button"
                  onClick={() => removeWishlistItem(index)}
                >
                  Remove
                </button>
              </div>
            ))}
            <div className="add-button">
              <button type="button" onClick={addWishlistItem}>
                {wishlist.length === 0 ? 'Add an item' : 'Add another item'}
              </button>
            </div>
          </div>

          {/* Favourite Reindeer */}
          <div>
            <label>Favourite Reindeer:</label>
            <select
              value={favouriteReindeer}
              onChange={(e) => setFavouriteReindeer(e.target.value)}
              required
            >
              <option value="">Select a Reindeer</option>
              {[
                'Blitzen',
                'Comet',
                'Cupid',
                'Dancer',
                'Dasher',
                'Donner',
                'Prancer',
                'Rudolph',
                'Vixen',
              ].map((reindeer) => (
                <option key={reindeer} value={reindeer}>
                  {reindeer}
                </option>
              ))}
            </select>
          </div>

          {/* Address */}
          <div>
            <label>Address:</label>
            <input
              type="text"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              required
            />
          </div>

          {/* Important People */}
          <div>
            <label>Important People:</label>
            {importantPeople.map((person, index) => (
              <div key={index} className="input-group">
                <select
                  value={person.relation}
                  onChange={(e) =>
                    handleImportantPeopleChange(index, 'relation', e.target.value)
                  }
                  required
                >
                  <option value="">Select Relation</option>
                  <option value="Aunt">Aunt</option>
                  <option value="Boyfriend">Boyfriend</option>
                  <option value="Brother">Brother</option>
                  <option value="Dad">Dad</option>
                  <option value="Daughter">Daughter</option>
                  <option value="Friend">Friend</option>
                  <option value="Girlfriend">Girlfriend</option>
                  <option value="Grandad">Grandad</option>
                  <option value="Grandma">Grandma</option>
                  <option value="Grandpa">Grandpa</option>
                  <option value="Grandson">Grandson</option>
                  <option value="Granddaughter">Granddaughter</option>
                  <option value="Guardian">Guardian</option>
                  <option value="Husband">Husband</option>
                  <option value="Mom">Mom</option>
                  <option value="Nana">Nana</option>
                  <option value="Partner">Partner</option>
                  <option value="Papa">Papa</option>
                  <option value="Sister">Sister</option>
                  <option value="Son">Son</option>
                  <option value="Step Brother">Step Brother</option>
                  <option value="Step Dad">Step Dad</option>
                  <option value="Step Mom">Step Mom</option>
                  <option value="Step Sister">Step Sister</option>
                  <option value="Uncle">Uncle</option>
                  <option value="Wife">Wife</option>
                </select>
                <input
                  type="text"
                  value={person.name}
                  onChange={(e) =>
                    handleImportantPeopleChange(index, 'name', e.target.value)
                  }
                  placeholder="Name"
                  required
                />
                <button
                  type="button"
                  onClick={() => removeImportantPerson(index)}
                >
                  Remove
                </button>
              </div>
            ))}
            <div className="add-button">
              {importantPeople.length < 10 && (
                <button type="button" onClick={addImportantPerson}>
                  {importantPeople.length === 0 ? 'Add an important person' : 'Add another important person'}
                </button>
              )}
            </div>
          </div>

          {/* School */}
          <div>
            <label>School:</label>
            <input
              type="text"
              value={school}
              onChange={(e) => setSchool(e.target.value)}
              required
            />
          </div>

          {/* Message to Santa */}
          <div>
            <label>Message to Santa:</label>
            <textarea
              value={messageToSanta}
              onChange={(e) => setMessageToSanta(e.target.value)}
              required
            ></textarea>
          </div>

          {/* Email Address */}
          <div>
            <label>Email Address:</label>
            <input
              type="email"
              name="email"
              id="email"
              autoComplete="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>

          {/* Submit Button */}
          <div className="submit-button-wrapper">
            <button type="submit">Send to Santa</button>
          </div>
        </form>
      </div>
      <footer className="footer">
        <p>Made with ❤️ in Alberta, Canada</p>
        <p>Contact: <a href="mailto:santa@christmas-letter.com">santa@christmas-letter.com</a></p>
      </footer>
    </div>
  );
}

export default SantaLetter;
