import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import ChristmasCountdown from './components/ChristmasCountdown';
import './App.css';

function ViewLetter() {
  const [letter, setLetter] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { letter_id } = useParams();

  useEffect(() => {
    const fetchLetter = async () => {
      const baseUrl = 'https://api.christmas-letter.com';

      try {
        const response = await fetch(`${baseUrl}/letter/${letter_id}`);
        if (!response.ok) {
          throw new Error('Letter not found');
        }
        const data = await response.json();
        setLetter(data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchLetter();
  }, [letter_id]);

  const handlePrint = () => {
    // Force a small delay to ensure styles are applied
    setTimeout(() => {
      const printWindow = window.open('', '_blank');
      if (!printWindow) {
        // If popup was blocked, fallback to direct printing
        window.print();
        return;
      }

      const letterContent = document.querySelector('.letter-view').cloneNode(true);
      // Remove no-print elements
      letterContent.querySelectorAll('.no-print').forEach(el => el.remove());

      const html = `
        <!DOCTYPE html>
        <html>
          <head>
            <title>Letter from Santa</title>
            <link href="https://fonts.googleapis.com/css2?family=Mountains+of+Christmas&family=Comic+Neue&display=swap" rel="stylesheet">
            <style>
              body {
                margin: 0;
                padding: 20px;
                font-family: 'Comic Neue', cursive;
              }
              .letter-view {
                max-width: 800px;
                margin: 0 auto;
                text-align: center;
              }
              .santa-message {
                font-size: 1.2rem;
                line-height: 1.5;
                text-align: left;
              }
              h1 {
                font-family: 'Mountains of Christmas', cursive;
                color: #d42426;
              }
              img {
                max-width: 200px;
              }
            </style>
          </head>
          <body>
            ${letterContent.outerHTML}
          </body>
        </html>
      `;

      printWindow.document.write(html);
      printWindow.document.close();
      
      // Wait for resources to load
      printWindow.onload = () => {
        printWindow.print();
        printWindow.onafterprint = () => printWindow.close();
      };
    }, 100);
  };

  const handleShare = async () => {
    try {
      if (navigator.share) {
        await navigator.share({
          title: 'My Letter from Santa',
          text: 'Check out my personalized letter from Santa!',
          url: window.location.href
        });
      } else {
        // Fallback for desktop - copy to clipboard
        await navigator.clipboard.writeText(window.location.href);
        alert('Link copied to clipboard!');
      }
    } catch (error) {
      console.error('Error sharing:', error);
    }
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;
  if (!letter) return <div>No letter found</div>;

  return (
    <div className="container">
      <div className="no-print">
        <ChristmasCountdown />
      </div>
      <div className="letter-view">
        <img src="/santa.webp" alt="Santa Claus" className="santa-image" />
        <h1 style={{ fontFamily: "'Mountains of Christmas', cursive" }}>Santa's Letter</h1>
        
        <div className="letter-content">
          <div className="santa-message" style={{ fontFamily: "'Comic Neue', cursive", fontSize: "1.2rem" }}>
            {letter.letterContent.split('\n').map((paragraph, index) => (
              <p key={index}>{paragraph}</p>
            ))}
          </div>
        </div>
        
        <div className="button-group no-print">
          <button 
            className="print-btn"
            onClick={handlePrint}
          >
            Print Letter
          </button>
          <button 
            className="write-another-btn"
            onClick={() => window.location.href = '/'}
          >
            Write Another Letter
          </button>
          <button 
            className="share-btn"
            onClick={handleShare}
          >
            Share Letter
          </button>
        </div>
      </div>
      <footer className="footer no-print">
        <p>Made with ❤️ in Alberta, Canada</p>
        <p>Contact: <a href="mailto:santa@christmas-letter.com">santa@christmas-letter.com</a></p>
      </footer>
    </div>
  );
}

export default ViewLetter;