import React, { useState, useEffect } from 'react';

function ChristmasCountdown() {
  const [timeUntilChristmas, setTimeUntilChristmas] = useState('');

  useEffect(() => {
    const calculateTimeUntilChristmas = () => {
      const now = new Date();
      const currentYear = now.getFullYear();
      const christmas = new Date(currentYear, 11, 25); // Month is 0-based, so 11 is December
      
      // If Christmas has passed this year, look forward to next year's Christmas
      if (now > christmas) {
        christmas.setFullYear(currentYear + 1);
      }
      
      const difference = christmas - now;
      
      const days = Math.floor(difference / (1000 * 60 * 60 * 24));
      const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((difference % (1000 * 60)) / 1000);
      
      return `${days} days, ${hours} hours, ${minutes} minutes, and ${seconds} seconds until Christmas!`;
    };

    setTimeUntilChristmas(calculateTimeUntilChristmas());
    const timer = setInterval(() => {
      setTimeUntilChristmas(calculateTimeUntilChristmas());
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  return (
    <div className="christmas-countdown" style={{ 
      textAlign: 'center', 
      padding: '20px', 
      backgroundColor: '#034f1d',
      color: '#ffffff',
      fontFamily: "'Mountains of Christmas', cursive",
      fontWeight: 'bold',
      marginBottom: '20px',
      borderRadius: '8px',
      boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
      fontSize: '1.8rem',
      border: '3px solid #c41e3a',
      position: 'relative',
      backgroundImage: 'linear-gradient(45deg, #034f1d 25%, #023816 25%, #023816 50%, #034f1d 50%, #034f1d 75%, #023816 75%, #023816 100%)',
      backgroundSize: '40px 40px'
    }}>
      <span style={{ 
        display: 'inline-block',
        textShadow: '2px 2px 4px rgba(0,0,0,0.3)',
        position: 'relative',
        zIndex: 1
      }}>
        {timeUntilChristmas}
      </span>
      <div style={{
        position: 'absolute',
        top: '-15px',
        left: '10px',
        fontSize: '2rem'
      }}>🎄</div>
      <div style={{
        position: 'absolute',
        top: '-15px',
        right: '10px',
        fontSize: '2rem'
      }}>🎅</div>
    </div>
  );
}

export default ChristmasCountdown;
