import React, { useEffect } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';
import SantaLetter from './SantaLetter';
import ViewLetter from './ViewLetter';
import './App.css';

ReactGA.initialize('G-78ZJ1RY7V6'); // Replace with your actual GA4 measurement ID

function App() {
  const location = useLocation();

  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: location.pathname });
  }, [location]);

  return (
    <Routes>
      <Route path="/" element={<SantaLetter />} />
      <Route path="/letter/:letter_id" element={<ViewLetter />} />
    </Routes>
  );
}

export default App;
